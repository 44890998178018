<template>
  <section>
    <main class="md:py-20 py-5 px-5 md:px-0">
      <!-- Most Popular Faq -->
      <div class="pt-4 py-5 lg:py-4">
        <h1
          class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-center capitalize py-4"
        >
          FAQs
        </h1>
        <div
          class="w-full md:[500px] lg:w-[1000px] mx-auto flex flex-col gap-2 py-[10px] md:py-6"
        >
          <div
            v-for="(item, index) in accordinItem.slice(
                  this.start,
                  this.end,
                )"
            :key="index"
            class="flex flex-col text-left px-[15px] py-[11px] md:px-[25px] md:py-[20px] gap-2 bg-white rounded-lg shadow-sm"
          >
            <div
              class="flex justify-between  cursor-pointer text-[#001c54]"
              @click="toggleAccordin(index)"
            >
              <h3
                class="text-[16px] md:text-md lg:text-[20px] text-[#001C54] Gilroy-SemiBold w-[90%] lg:w-[95%]"
              >
                {{ item.title }}
              </h3>
              <div class=" ">
                <div
                  v-if="this.activeAccordin !== index"
                  class="pt-[3px] pl-[4px]"
                >
                <img src="@/assets/program/chevron-top.png" alt="" srcset="" class=" w-7  h-7 ">
                  <!-- <font-awesome-icon icon="fa-angle-down" class="" /> -->
                </div>
                <div v-else class="pt-[3px] pl-[4px]">
                   <img src="@/assets/program/down-arrows.png" alt="" srcset="" class="w-7 h-7">
                  <!-- <font-awesome-icon icon="fa-angle-up" class="" /> -->
                </div>
              </div>
            </div>

            <p
              class="text-[14px] lg:text-[16px] w-full text-[#1f1f1f]"
              v-show="activeAccordin === index"
              v-html="item.content"
            ></p>
          </div>
        </div>
        <!-- <div
          v-if="this.end < this.accordinItem.length"
          class="w-full md:flex md:justify-center md:items-center py-2"
        >
          <button
            @click="viewMore"
            class="w-[140px] bg-[#f8c300] border rounded-xl md:rounded-lg py-[6px] md:py-[8px] font-semibold"
          >
            Load More!
          </button>
        </div> -->
        <!-- <div v-else>
          <button
            v-if="this.end > 3"
            @click="viewLess"
            class="w-[140px] bg-[#f8c300] border rounded-xl md:rounded-lg py-[6px] md:py-[8px] font-semibold"
          >
            Load Less!
          </button>
        </div> -->
      </div>
    </main>
  </section>
</template>

<script>
export default {
  name: "FaqView",
  data() {
    return {
      faqToShow: 6,
      accordinItem: [
        {
          id: 1,
          title: "1. Why choose Sharda Online?",
          content:
            "Sharda Online is a prestigious institution accredited by NAAC A+, renowned for its dedication to delivering top-tier education through innovative online degree programs. Recognized by the University Grants Commission (UGC), Sharda Online's online degree offerings adhere to rigorous quality standards, ensuring they are equivalent to traditional degrees.<br><br>1) Technology-Driven Learning: Benefit from state-of-the-art technology combined with interactive learning methodologies.<br>2) Outcome-Focused Approach: Acquire practical skills tailored to industry needs through outcome-oriented learning.<br>3) Wide Range of Electives: Explore over 18 specialized courses to customize your learning experience.<br>4) Global Reach: Connect with learners worldwide, fostering a diverse and inclusive learning community.<br>5) Extensive Learning Opportunities: Access more than 2,400 hours of learning materials via our robust Learning Management System (LMS).<br>6) Holistic Learning: Participate in co-curricular and extra-curricular activities to foster creativity, leadership skills, and community engagement.<br>7) Career Development and Competence Building with Placement Assistance: Receive guidance and resources to enhance your career prospects, develop essential skills, and access placement assistance.",
        },
        {
          id: 2,
          title:
            "2. What types of online degree programs are offered by Sharda Online?",
          content:
            "Sharda Online offers a variety of UGC Entitled online degree programs.<br>a) Online BBA<br> b) Online BCA<br> c) Online BA (Hons.)<br> d) Online MBA<br> e) Online MCA<br> f) Online M.Com ",
        },
        {
          id: 3,
          title:
            "3. How do I enroll in an online program at Sharda Online?",
          content:
            "To enroll in our online program, complete the enquiry form, and our admissions team will contact you to guide you through the process.",
        },
        {
          id: 4,
          title: "4. What is the duration of the programs offered by Sharda Online?",
          content:
            "For Bachelors programs : 3 years (6 semesters),<br> For Masters programs : 2 years (4 semesters) ",
        },
        {
          id: 5,
          title: "5. Are online degrees recognized by UGC?",
          content:
            "Yes, the online degree programs from Sharda Online are recognized by the University Grants Commission (UGC).",
        },
        
      ],
      activeAccordin: null,
      error: null,
      start: 0,
      end: 6,
    };
  },
  methods: {
    toggleAccordin(index) {
      this.activeAccordin = this.activeAccordin === index ? null : index;
    },
    viewMore() {
      this.end += 2;
    },
    viewLess() {
      this.end = 4;
    },
  },
  computed: {
    showMore() {
      return this.faqToShow < this.accordinItem.length;
    },
    showLess() {
      return this.faqToShow > 4 && !this.showMore;
    },
  },
};
</script>

<style></style>
