import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LP_Program from "../views/LP_Program.vue";
import LP_ProgramThankyou from "../views/LP_ProgramThankyou.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/lp/online-degree-programs",
    name: "LP_Program",
    component: LP_Program,
  },
  {
    path: "/lp/online-degree-programs/thankyou",
    name: "LP_ProgramThankyou",
    component: LP_ProgramThankyou,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
