<template>
  <div>
    <BannerView />
    <AboutView />
    <AwardsView />
    <OnlineProgram />
    <HiglightView />
    <WhoApply />
    <HiringView />
    <CareerSupport />
    <FaqView />
    <div class="pb-[80px] md:pb-0">
      <FooterView />
    </div>
    <LpStickyView />
  </div>
</template>

<script>
import BannerView from "@/components/LearningShalaThankyou/BannerView.vue";
import AboutView from "@/components/LearningShala/AboutView.vue";
import AwardsView from "@/components/LearningShala/AwardsView.vue";
import OnlineProgram from "@/components/LearningShala/OnlineProgram.vue";
import HiglightView from "@/components/LearningShala/HiglightView.vue";
import WhoApply from "@/components/LearningShala/WhoApply.vue";
import HiringView from "@/components/LearningShala/HiringView.vue";
import CareerSupport from "@/components/LearningShala/CareerSupport.vue";
import FaqView from "@/components/LearningShala/FaqView.vue";
import FooterView from "@/components/LearningShala/FooterView.vue";
import LpStickyView from "@/components/LearningShala/LpStickyView.vue";

export default {
  name: "LP_ProgramThankyou",
  components: {
    BannerView,
    AboutView,
    AwardsView,
    OnlineProgram,
    HiglightView,
    WhoApply,
    HiringView,
    CareerSupport,
    FaqView,
    FooterView,
    LpStickyView,
  },
};
</script>

<style></style>
